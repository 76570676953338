import React, { useEffect, useState } from "react";
import "./ExcelUploader.css";
import { toast } from "react-toastify";
import { GrNext, GrPrevious } from "react-icons/gr";
import MyNavbar from "../Layout/Navbar/Navbar";
import { useDropzone } from "react-dropzone";
import { Modal, Button, Table } from "react-bootstrap";
import api from "../../Service/Api";
import Loading from "../Common/Loading";
import { FaEye, FaDownload, FaTrash } from "react-icons/fa";
import axios from "axios";

const dummyData = [
  {
    Name: "Ujjwal Kant",
    "Mobile Number": "9635004814",
    "e-mail ID": "ujjwal.kant@rebininfotech.com",
    Role: "Developer",
    Address: "Kolkata",
    "Company Name": "REBI-NINFOTECH",
    "Company Address": "Bangur",
  },
  {
    Name: "Rudranil Banerjee",
    "Mobile Number": "6294826829",
    "e-mail ID": "rudranil.banerjee@rebininfotech.com",
    Role: "Developer",
    Address: "Kolkata",
    "Company Name": "REBI-NINFOTECH",
    "Company Address": "Bangur",
  },
  {
    Name: "Saurabha Das",
    "Mobile Number": "7365993789",
    "e-mail ID": "saurabha.das@rebininfotech.com",
    Role: "Developer",
    Address: "Kolkata",
    "Company Name": "REBI-NINFOTECH",
    "Company Address": "Bangur",
  },
  {
    Name: "Aman Arial Ansari",
    "Mobile Number": "6200522210",
    "e-mail ID": "aman.ansari@rebininfotech",
    Role: "Digital Marketer",
    Address: "Kolkata",
    "Company Name": "REBI-NINFOTECH",
    "Company Address": "Bangur",
  },
  {
    Name: "Ujjwal Kant",
    "Mobile Number": "9635004814",
    "e-mail ID": "ujjwal.kant@rebininfotech.com",
    Role: "Developer",
    Address: "Kolkata",
    "Company Name": "REBI-NINFOTECH",
    "Company Address": "Bangur",
  },
  {
    Name: "Rudranil Banerjee",
    "Mobile Number": "6294826829",
    "e-mail ID": "rudranil.banerjee@rebininfotech.com",
    Role: "Developer",
    Address: "Kolkata",
    "Company Name": "REBI-NINFOTECH",
    "Company Address": "Bangur",
  },
  {
    Name: "Saurabha Das",
    "Mobile Number": "7365993789",
    "e-mail ID": "saurabha.das@rebininfotech.com",
    Role: "Developer",
    Address: "Kolkata",
    "Company Name": "REBI-NINFOTECH",
    "Company Address": "Bangur",
  },
  {
    Name: "Aman Arial Ansari",
    "Mobile Number": "6200522210",
    "e-mail ID": "aman.ansari@rebininfotech",
    Role: "Digital Marketer",
    Address: "Kolkata",
    "Company Name": "REBI-NINFOTECH",
    "Company Address": "Bangur",
  },
];

function ExcelUpload() {
  const [file, setFile] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [loading, setLoading] = useState(false);
  // const fileInputRef = useRef(null);

  const itemsPerPage = 10;
  const totalPages = Math.ceil(uploadedFiles.length / itemsPerPage);

  const { getRootProps, getInputProps } = useDropzone({
    accept: [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
      "application/vnd.ms-excel.sheet.macroEnabled.12",
      "application/vnd.ms-excel.template.macroEnabled.12",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
      "application/vnd.ms-excel.addin.macroEnabled.12",
    ],
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        setFile(acceptedFiles[0]);
      }
    },
  });

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    if (!file) {
      toast.warning("Please select a valid Excel file.");
      setLoading(false);
      return;
    }
    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await api.post("upload/uploadExcel", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 200) {
        fetchData();
        toast.success("File uploaded successfully!");
        setFile(null);
      } else {
        toast.error("Failed to upload file!");
      }
    } catch (error) {
      toast.warning("An error occurred while uploading the file.");
    }
    setLoading(false);
  };

  const fetchData = async () => {
    try {
      const response = await api.get("masterList/getExcelList");
      console.log("response.data :>> ", response.data);
      setUploadedFiles(response.data);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = (url, filename) => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      const url = `masterList/deleteFile/${id}`;
      const response = await api.delete(url);
      console.log('response.data :>> ', response.data);
      toast.success(response.data.message);
      fetchData();
    } catch (error) {
      toast.error("Error fetching data:", error.message);
    }
    // const updatedFiles = uploadedFiles.filter((_, i) => i !== id);
    // setUploadedFiles(updatedFiles);

    // if (
    //   (currentPage - 1) * itemsPerPage >= updatedFiles.length &&
    //   currentPage > 1
    // ) {
    //   setCurrentPage(currentPage - 1);
    // }
    // toast.success("Record deleted Successfully");
    setLoading(false);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getCurrentPageFiles = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return uploadedFiles.slice(startIndex, endIndex);
  };
  const currentPageFiles = getCurrentPageFiles();

  const handlePreview = async (id) => {
    try {
      const url = `masterList/filedata/${id}`;

      const response = await api.get(url);
      console.log("response.data :>> ", response.data);
      setModalData(response.data);

      setShowModal(true);
    } catch (error) {
      toast.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      {loading && <Loading />}
      <div className="containers">
        <MyNavbar />
        <div className="container">
          <h1>Upload File</h1>
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            {file ? (
              <p>{file.name}</p>
            ) : (
              <p>Drag 'n' drop a file here, or click to select a file</p>
            )}
          </div>
          <button className="btn btn-primary" onClick={handleSubmit}>
            Upload
          </button>
          {uploadedFiles.length > 0 && (
            <div>
              <h2>Uploaded Files</h2>
              <table className="file-table">
                <thead>
                  <tr>
                    <th>S. No.</th>
                    <th>File Name</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentPageFiles.map((file, index) => (
                    <tr key={file.id}>
                      <td style={{ width: "10%" }}>
                        {(currentPage - 1) * itemsPerPage + index + 1}
                      </td>
                      {console.log(file.fileName)}
                      <td>{file.fileName}</td>
                      <td style={{ width: "15%", textAlign: "center" }}>
                        <button
                          className="primary"
                          onClick={() => handlePreview(file.id)}
                        >
                          <FaEye />
                        </button>
                        <a
                          className="download"
                            href={file.fileURL}
                          download={file.fileName}
                          // onClick={(e) => {
                          //   e.preventDefault();
                          //   handleDownload(file.fileURL, file.fileName);
                          // }}
                        >
                          <FaDownload />
                        </a>
                        {/* <button
                          className="download"
                          download={file.fileName}
                          onClick={() => handleDownload(file.fileURL)}
                        >
                          <FaDownload />
                        </button> */}
                        <button
                          className="delete"
                          onClick={() => handleDelete(file.id)}
                        >
                          <FaTrash />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <br />
              <div className="pagination-container">
                <div className="pagination">
                  <button
                    className="btn btn-primary prev"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    <GrPrevious />
                  </button>
                  {Array.from({ length: totalPages }, (_, index) => (
                    <button
                      key={index}
                      className={`btn btn-primary btnIndex ${
                        currentPage === index + 1 ? "active" : ""
                      }`}
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </button>
                  ))}
                  <button
                    className="btn btn-primary next"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    <GrNext />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <Modal show={showModal} onHide={handleCloseModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>File Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalData.length > 0 ? (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>Name</th>
                  <th>Mobile Number</th>
                  <th>E-mail ID</th>
                  <th>Details</th>
                  <th>Address</th>
                  <th>Company Name</th>
                  <th>Company Address</th>
                </tr>
              </thead>
              <tbody>
                {modalData.map((data, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    {Object.values(data).map((value, idx) => (
                      <td key={idx}>{value}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No data available</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ExcelUpload;
