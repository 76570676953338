import React, { useState } from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import '../Navbar/Navbar.css'
import { IoIosMenu } from "react-icons/io";
import Sidebar from '../SideBar/SideBar';
import { Link } from 'react-router-dom';
// import logo from '../../../assets/logo.png'
import  logoIcon from '../../../assets/logo-icon-rebin.png'

function MyNavbar() {
  const [sideBarOpen, setSideBarOpen] = useState(false);

  const toggleSidebar = () => {
    setSideBarOpen(!sideBarOpen)
  }

  // console.log('sideBarOpen :>> ', sideBarOpen);

  return (
    <>
      {sideBarOpen && <Sidebar setSideBarOpen={setSideBarOpen} />}
      <Navbar bg="primary" variant="dark" expand="lg" className='navbarHeader'>
        <div className="menuIconWrapper">
          <IoIosMenu onClick={() => toggleSidebar()} className="menuIcon" />
        </div>
        <Container>
          <Navbar.Brand>
            <Link to="/dashboard">
              <img src={logoIcon} alt="Logo" className="logoNav" />
            </Link>
          </Navbar.Brand>
          {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
          {/* <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/dashboard">Home</Nav.Link>
              <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse> */}
        </Container>
      </Navbar>
    </>
  );
};

export default MyNavbar;
