import React from 'react'
import { Container } from 'react-bootstrap'
// import './Dashboard.scss';
// import '../../Components/Dashboard/Dashboard.scss'
// import '../Dashboard/Dashboard.scss'
import '../Dashboard/Dashboard.css'
import logo from '../../assets/logo.png'
function Dashboard() {
  return (
    <>
      <div className='containers'>
        <Container size="xl" className='dashboard-container'>
          <div>
          <img src={logo} alt="Logo" className="dashboard-log" />
          </div>
        </Container>
      </div>
    </>
  )
}

export default Dashboard