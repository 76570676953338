import React, { createContext, useState, useContext, useEffect } from 'react';
import api from '../Service/Api';
import { useLocation, useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({ isAuthenticated: null, loading: false });
    const navigate = useNavigate();
    const location = useLocation();
    
    const checkAuthorization = async () => {
        setAuth(prev => ({ ...prev, loading: true }));
        try {
            const response = await api.get('/auth/protected');
            if (response.data) {
                const { isAuthorized } = response.data;
                setAuth({ isAuthenticated: isAuthorized, loading: false });

                if (isAuthorized) {
                    if (location.pathname === '/') {
                        navigate('/dashboard')
                    } else {
                        navigate(location.pathname, { replace: true });
                    }
                } else {
                    navigate('/', { replace: true });
                }
            }
        } catch (error) {
            setAuth({ isAuthenticated: false, loading: false });
            navigate('/', { replace: true });
        }
    };

    useEffect(() => {
        checkAuthorization();
    }, []);

    return (
        <AuthContext.Provider value={{ auth, setAuth }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
