import React, { useState } from 'react';
import './ForgotPassword.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../../../assets/logo.png';
import Loading from '../../Common/Loading';
import { Link } from 'react-router-dom';

function Forgot() {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    // const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!email) {
            toast.warning('Email field required.');
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            toast.warning('Email address is invalid.');
        } else {
            if (email) {
                setLoading(true);
                setTimeout(() => {
                    setLoading(false);
                    toast.success('Reset Url Sent');
                    // navigate('/ExcelUploader');
                }, 2000);
                setTimeout(() => {
                    setLoading(false);
                    toast.success('Redirecting to Sign Page');
                    // navigate('/');
                }, 3000);
            } else {
                toast.error('Invalid email');
            }
        }
    };

    return (
        <>
            {loading && <Loading />}
            <div className='body'>
                <div className="forgot-container">
                    <form className="login-form" onSubmit={handleSubmit}>
                        <div className="logo-container">
                            <img src={logo} alt="Logo" className="logoForgot" />
                        </div>
                        <h5 className="header-container">Forgot</h5>
                        <div className="form-group">
                            <input
                                type="email"
                                value={email}
                                placeholder='Enter Email'
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <button type="submit" className="login-button">SUBMIT</button>
                        <div className="additional-options">
                            <Link to="/" className="sign-in">Sign In</Link>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default Forgot;
