import './App.css';
import { Routes, Route, } from 'react-router-dom';
import Login from './Components/Auth/login';
import { ToastContainer } from 'react-toastify';
import ExcelUploader from './Components/Excel-Uploader/ExcelUploader';
import Loading from './Components/Common/Loading';
import Forgot from './Components/Auth/Forgot/ForgotPassword';
import Navigation from './Navigation';
import PrivateRoute from './Components/Routes/PrivateRoutes';
// import AuthProvider, { useAuth } from './Context/AuthContext';
import ServicesPage from './Components/Services/Service';
import { useAuth } from './Context/AuthContext';
import About from './Components/About/About';
import ContactUs from './Components/ContactUs/ContactUs';
import ContactInfo from './Components/ContactUs/ContactUs';
function App() {
  const { auth } = useAuth();
  if(auth.loading){
    return (<Loading/>)
  }
  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      
        <Routes>
          {/* <Route path='*' element={<Navigate path='/dashboard'/>} /> */}
          <Route path='/' element={<Login />} />
          <Route path='/ExcelUploader' element={<PrivateRoute component={ExcelUploader} />} />
          <Route path='/Loader' element={<Loading />} />
          <Route path='/forgot-Password' element={<Forgot />} />
          <Route path='/service' element={<ServicesPage />} />
          <Route path='/about' element={<About />} />
          <Route path='/dashboard' element={<Navigation />} />
          {/* <Route path='/contact' element={<ContactInfo />} /> */}
        </Routes>
      {/* <Dashboard /> */}
    </div>
  );
}

export default App;
