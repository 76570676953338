// src/components/About.js
import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import MyNavbar from '../Layout/Navbar/Navbar';
import './../About/About.css'

const About = () => {
    return (
        <div>
            <MyNavbar />
            <Container className="py-5 aboutContainer">
                <Row className="align-items-center">
                    <Col md={6}>
                        <Image src="https://via.placeholder.com/300" rounded fluid />
                    </Col>
                    <Col md={6}>
                        <h2><strong>About Us</strong></h2>
                        <p className='aboutP'>
                            Welcome to <strong>REBIN-INFOTECH!</strong> We are dedicated to providing the best service to our customers. Our team of experts
                            is committed to excellence and delivering top-notch solutions to meet your needs.
                        </p>
                        <p className='aboutP'>
                            With 9 years of experience in the industry, we have built a reputation for reliability and quality. Our mission
                            is to help you achieve your goals with our innovative and tailored approaches.
                        </p>
                        <p className='aboutP'>
                            Thank you for choosing us. We look forward to working with you and helping you succeed.
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default About;
