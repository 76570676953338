import React from "react";
import { Container, Row, Col, Card, Navbar, Nav } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import MyNavbar from "../Layout/Navbar/Navbar";
// import './ServicesPage.css';
import "./../Services/Service.css";

const services = [
  {
    title: "Web Development",
    description: "Building responsive and modern web applications.",
    imgSrc: "https://via.placeholder.com/150",
  },
  {
    title: "Mobile App Development",
    description: "Creating cross-platform mobile applications.",
    imgSrc: "https://via.placeholder.com/150",
  },
  {
    title: "UI/UX Design",
    description: "Designing user-friendly interfaces and experiences.",
    imgSrc: "https://via.placeholder.com/150",
  },
  {
    title: "SEO Services",
    description: "Improving your website’s search engine ranking.",
    imgSrc: "https://via.placeholder.com/150",
  },
  {
    title: "Digital Presence Enhancement",
    description: `Elevate online visibility, engagement with expert digital strategies.`,
    imgSrc: "https://via.placeholder.com/150",
  },
  {
    title: "Innovative Design Solutions",
    description: `Transform brand identity with visually stunning creative solutions.`,
    imgSrc: "https://via.placeholder.com/150",
  },
];

const ServicesPage = () => {
  return (
    <div>
      <MyNavbar />
      {/* <Container> */}
        {/* Header Section */}
        <Container fluid className="text-center bg-light py-5 serviceContainer">
          <h1 className="display-4">Our Services</h1>
          <p className="lead">
            We offer a wide range of services to meet your needs.
          </p>
        </Container>

        {/* Services Section */}
        <Container className="py-5">
          <Row>
            {services.map((service, index) => (
              <Col md={6} lg={4} key={index} className="mb-4">
                <Card className="cardCss">
                  <Card.Img variant="top" src={service.imgSrc} />
                  <Card.Body>
                    <Card.Title>{service.title}</Card.Title>
                    <Card.Text>{service.description}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      {/* </Container> */}
    </div>
  );
};

export default ServicesPage;
