import React from 'react';
import { useAuth } from './../../Context/AuthContext';
import { useNavigate } from 'react-router-dom';
import Loading from '../Common/Loading';

const PrivateRoute = ({ component: Component }) => {
    const { auth } = useAuth();
    // console.log(auth)
    const navigate = useNavigate();
    if (auth.isAuthenticated) {
        return <Component/>
    } else {
        navigate('/');
    }
    return null
};

export default PrivateRoute;
