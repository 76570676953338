import React, { useEffect, useRef, } from 'react';
import '../SideBar/SideBar.css';
import { IoIosMenu } from "react-icons/io";
import { Link, useNavigate } from 'react-router-dom';
import api from '../../../Service/Api';
import { toast } from 'react-toastify';
import { useAuth } from '../../../Context/AuthContext';

const Sidebar = ({ setSideBarOpen }) => {
    const sidebarRef = useRef(null);
    const navigate = useNavigate();
    const { setAuth } = useAuth();

    const handleClickOutside = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
            setSideBarOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const logout = async () => {
        try {
            const res = await api.post('/auth/logout');
            console.log(res)
            setAuth({ isAuthenticated: false, loading: false });
            navigate('/');

            toast.success(res.data.message);
        } catch (error) {
            toast.error(error.message);
        }
    };

    return (
        <>
            {<div className="sidebar" ref={sidebarRef}>
                <h2 className="sidebar-title">
                    <IoIosMenu className='menuIconSideBar' onClick={() => setSideBarOpen(false)} style={{ cursor: 'pointer' }} />
                </h2>
                <ul className="sidebar-list">
                    <li className="sidebar-item">
                        <Link to="/ExcelUploader"
                            className="sidebar-link pointer">Excel-Uploader</Link></li>
                    <li className="sidebar-item"><Link to='/service' className="sidebar-link">Services</Link></li>
                    <li className="sidebar-item"><Link to="/about" className="sidebar-link">About</Link></li>
                    {/* <li className="sidebar-item"><Link to="/contact" className="sidebar-link">Contact</Link></li> */}
                </ul>

                <div className="sidebar-footer">
                    <button className="logout-button btn btn-dark" onClick={logout}>Logout</button>
                </div>
            </div>}
        </>
    );
};

export default Sidebar;
