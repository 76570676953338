import React, { useEffect, useState } from 'react';
import './Login.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../../assets/logo.png';
import { Link, useNavigate } from 'react-router-dom';
import Loading from '../Common/Loading';
// import users from '../../assets/users.json';
// import serverUrl from '../../Components/Environment/Config'
import { baseUrl } from '../Environment/Config';
import { useAuth } from '../../Context/AuthContext';
import api from '../../Service/Api';

function Login() {
    const { auth, setAuth } = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const forgoPassword = () => {
        navigate('/forgot-Password');
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        api.post(`${baseUrl}auth/login`, { email, password }, { withCredentials: true })
            .then((response) => {
                setLoading(false);
                if (response.data.message === "Login successful") {
                    toast.success(response.data.message);
                    setAuth({ isAuthenticated: true, loading: false });
                    navigate('/dashboard');
                } else {
                    toast.warning('Network response was not ok');
                }
            })
            .catch((error) => {
                // console.error('Error:', error);
                toast.error(error.response?.data?.message || error.message);
            });
        setLoading(false);
    };
    useEffect(() => {
        if (auth.isAuthenticated) {
            const prevPath = localStorage.getItem('prevPath') || '/dashboard';
            navigate(prevPath, { replace: true });
        }
    }, [auth.isAuthenticated, navigate]);

    if(auth.isAuthenticated===false){
        return (
            <>
                {loading && <Loading />}
                <div className="login-container">
                    <form className="login-form" onSubmit={handleSubmit}>
                        <div className="logo-container">
                            <img src={logo} alt="Logo" className="logo" />
                        </div>
                        <h5 className="header-container">SIGN IN</h5>
                        <div className="form-group">
                            <input
                                type="email"
                                value={email}
                                placeholder='Enter Email'
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="password"
                                value={password}
                                placeholder='Enter Password'
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <button type="submit" className="login-button">LOG IN</button>
                        <div className="additional-options">
                            <label className="remember-me">
                                <input
                                    type="checkbox"
                                    checked={rememberMe}
                                    onChange={(e) => setRememberMe(e.target.checked)}
                                />
                                <p>Remember Me</p>
                            </label>
                            <Link to='forgot-Password' className="forgotPassword"
                                onClick={() => forgoPassword()}>Forgot Password?</Link>
                        </div>
                    </form>
                </div>
            </>
        );
    }else{
        return null;
    }
    

};

export default Login;