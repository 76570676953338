import React from 'react';
import { TbLoader } from "react-icons/tb";

const Loading = () => {
  return (
    <div style={styles.container}>
      <TbLoader style={styles.icon} />
    </div>
  );
};
const styles = {
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    zIndex: 1000
  },
  icon: {
    fontSize: '4rem',
    color: '#007bff',
    animation: 'spin 2s linear infinite'
  }
};
const styleSheet = document.styleSheets[0];
styleSheet.insertRule(`
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`, styleSheet.cssRules.length);
export default Loading;
