import React from 'react'
import MyNavbar from './Components/Layout/Navbar/Navbar'
import Dashboard from './Components/Dashboard/Dashboard'

const Navigation = () => {
    return (
        <div>
            <MyNavbar />
            <Dashboard />
        </div>
    )
}

export default Navigation